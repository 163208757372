
import Header from "./Header";
import Footer from "./Footer";
const Layout = ( props ) => { 
    return (
        <div>
            

          
            { props.children }
           
        </div>
    );

};

export default Layout;