import React, { Component } from "react";
import $ from "jquery";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import logoWhite from "../assets/img/logo-white-icon.png";
import testImage from "../assets/img/testimoinal-1.jpg";

class HomeMobileSlider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const options = {
      nav: false,
      loop: true,
      margin: 1,
      autoplay: true,
      dots: true,
      navText: [
        ' <a className="left carousel-control " data-slide="prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 407.4 407.4" fill="#fff" width="30"><path d="M316 21L295 0 92 204l203 203 21-21-182-182z"/></svg><span className="sr-only">Previous</span> </a> ',
        '<a className="right carousel-control "  data-slide="next"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 407.4 407.4" fill="#fff" width="30"><path d="M113 0L92 21l182 183L92 386l21 21 203-203z"/></svg> <span className="sr-only">Next</span> </a>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        450: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    };

    return (
      <OwlCarousel
        className={"owl-theme owl-carousel mobile-slider"}
        options={options}
      >
        <div className="item ">
          <div className="quality-area pd-top-105 pd-bottom-150 position-relative background-right">
            <div className="container-fluid position-relative take-top1">
              <div className="row col-11 mx-auto p-0">
                <div className="align-self-center col-lg-6 offset-lg-6 ">
                  <div className=" mt-5 mt-lg-0">
                    <div className="pb-4">
                      <img src={logoWhite} />
                    </div>

                    <p className="text-white let-space-7 fnt-26 text-uppercase fnt-trap mb-3">
                      QUALITY MEANS A LOT TO US.
                    </p>
                    <h2 className="title p-0 text-yellow">
                      What goes into making our juices?
                    </h2>

                    <div className="col-lg-8 col-12 p-0">
                      <h5 className="text-white fnt-24  pb-0 pt-5 mt-5 bdr-tp-5-yellow">
                        Always Organic.
                      </h5>
                      <p className="text-white fnt-16">
                      All our produce is organic and locally sourced and that isn't going to change. Our partner farms take the utmost care to maintain their quality standards and we make sure that you see those benefits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="item ">
          <div className="whatgoes-area pd-top-105 pd-bottom-150 position-relative background-left">
            <div className="container-fluid position-relative take-top1">
              <div className="row col-11 mx-auto">
                <div className="align-self-center col-lg-5 ">
                  <div className=" mt-5 mt-lg-0">
                    <div className="pb-4">
                      <img src={logoWhite} />
                    </div>
                    <p className="text-white let-space-7 fnt-26 text-uppercase fnt-trap mb-3">
                      YOU ARE WHAT YOU EAT
                    </p>
                    <h2 className="title p-0 text-yellow">
                      What goes into making our juices?
                    </h2>

                    <div className="col-lg-8 col-12 p-0">
                      <h5 className="text-white fnt-24  pb-0 pt-5 mt-5 bdr-tp-5-yellow">
                        Makes you feel light!
                      </h5>
                      <p className="text-white fnt-16">
                      We've all had the experience of feeling heavy and bloated after overworking, stressing or overeating. Our juices help put the lightness back in your step!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="item ">
          <div className="lightness-area pd-top-105 pd-bottom-150 position-relative background-right">
            <div className="container-fluid position-relative take-top1">
              <div className="row col-11 mx-auto">
                <div className="align-self-center col-lg-5 offset-lg-7">
                  <div className=" mt-5 mt-lg-0">
                    <div className="pb-4">
                      <img src={logoWhite} />
                    </div>
                    <p className="text-white let-space-7 fnt-26 text-uppercase fnt-trap mb-3">
                      LIGHTNESS IN A BOTTLE
                    </p>
                    <h2 className="title p-0 text-yellow">
                      What goes into making our juices?
                    </h2>

                    <div className="col-lg-8 col-12 p-0">
                      <h5 className="text-white fnt-24  pb-0 pt-5 mt-5 bdr-tp-5-yellow">
                        It's healthy AND delicious!
                      </h5>
                      <p className="text-white fnt-16">
                      Most health food tastes terrible and that keeps people away from being healthy. We fixed that so our juices are a yummy, healthy treat!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    );
  }
}

export default HomeMobileSlider;
