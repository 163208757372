import React, { Component } from 'react';

import VSideo from "./components/video";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";
import OwlCarousel from "react-owl-carousel2";
import logoWhite from "./assets/img/logo-white-icon.png";
import bannerOffer from "./assets/img/banner-offer.jpg";
import HomeMobileSlider from "./components/HomeMobileSlider";
import HomeDesktopContent from "./components/HomeDesktopContent";
import HomeFooterContent from "./components/HomeFooterContent";
import TestSlider from "./components/TestSlider";

import { Helmet } from "react-helmet";
import $ from "jquery";

class Index extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <div>
        <Helmet>
          {" "}
          <title>Home | Refreshing Ayurwedic Wellness | RAW </title>
          <meta name="description" content="Visit our home page to order online, to learn more about how we maintain quality and offer healthy juices, about our happy clients, and many more." />
          <link rel="canonical" href="https://rawsrilanka.com/" />
        </Helmet>

        <div className="align-items-center banner-area d-flex justify-content-center main style-two bg-darktint">
          <div id="bgvid">
          <VSideo />
          </div>

          <div className="container-fluid position-relative take-top2">
            <div className="row col-11 mx-auto p-0">
              <div className="col-lg-12 col-md-12 align-self-center">
                <div className="banner-inner padd-tp-300">
                  <p className="text-white let-space-7 fnt-26 text-uppercase fnt-trap mb-3">
                    FRESH. DELICIOUS. ORGANIC.
                  </p>
                  <h2 className="text-white mb-0">Cold Pressed Juices!</h2>
                </div>
              </div>
            </div>
          </div>
          <span className="scroll-btn take-top2">
            <a href="#">
              <span className="mouse">
                <span>
                  <svg width="100%" viewBox="0 0 50 42">
                    <path
                      className="tear"
                      d="M15 6
                                         Q 15 6, 25 18
                                         A 12.8 12.8 0 1 1 5 18
                                         Q 15 6 15 6z"
                    />
                  </svg>
                </span>
              </span>
            </a>
            <p>scroll Down</p>
          </span>
        </div>

        {/* <div className="about-area  bg-yellow">
          <div className="container">
            <a href="https://rawsrilanka.com/store/product/bundle-offer/">
            <img src={bannerOffer} className="img-fluid"/>
            </a>
             
            </div>
        </div> */}

        <div className="about-area pd-top-105 pd-bottom-150 bg-info ">
          <div className="container-fluid">
            <div className="row col-11 mx-auto p-0">
              <div className="col-lg-7 align-self-center">
                <div className=" mt-5 mt-lg-0">
                  <p className="text-white let-space-7 fnt-26 text-uppercase fnt-trap mb-3">
                    FIVE YEARS. <br />
                    THOUSANDS OF HAPPY CLIENTS.
                  </p>
                  <h2 className="title col-lg-8 p-0 fnt-60 text-white">
                    Why your friends love our juices.
                  </h2>
                </div>
              </div>
              <div className="col-lg-5 align-self-top  text-right ">
                <img src={logoWhite} class="d-none d-lg-block ml-auto" />
              </div>

              <div className="col-lg-12 align-self-center"></div>
              <TestSlider />
            </div>
          </div>
        </div>

        <MediaQuery minDeviceWidth={768}>
          <div>
            <HomeDesktopContent />
            <HomeFooterContent />
          </div>
        </MediaQuery>

        <MediaQuery maxDeviceWidth={767}>
          <div>
            <HomeMobileSlider />
            <HomeFooterContent />
          </div>
        </MediaQuery>

       
      </div>
    );
  }
}

export default Index;
