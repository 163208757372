import React, { Component } from "react";
import $ from "jquery";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import logoWhite from "../assets/img/logo-white-icon.png";
import testImage from "../assets/img/testimoinal-1.jpg";

class HomeFooterContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
       <div>
          <div className="healthyis-area pd-top-105 pd-bottom-150 position-relative background-left">
            <div className="container-fluid position-relative take-top1">
              <div className="row col-11 mx-auto">
                <div className="align-self-center col-lg-5">
                  <div className=" mt-5 mt-lg-0">
                    <div className="pb-4">
                      <img src={logoWhite} />
                    </div>
                    <p className="text-white let-space-7 fnt-26 text-uppercase fnt-trap mb-3">
                      HEALTHY IS EASY
                    </p>
                    <h2 className="title p-0 text-yellow">
                      We've made staying healthy, easier.
                    </h2>

                    <div className="col-lg-8 col-12 p-0">
                      <h5 className="text-white fnt-24  pb-0 pt-5 mt-5 bdr-tp-5-yellow">
                        Subscriptions. Cleanses. One-offs.
                      </h5>
                      <p className="text-white fnt-16">
                      Whether you want to commit to a healthy lifestyle, juice-cleanse once a month or just try it out, we've got a package that will help you along!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shop-refresh water-bubble-h pd-top-105 pd-bottom-150 position-relative yellow">
          <div className="container-fluid position-relative take-top1">
            <div className="row col-11 mx-auto">
              <div className="align-self-center col-lg-6">
                <div className=" mt-5 mt-lg-0">
                  <div className="pb-4">
                    <img src={logoWhite} />
                  </div>
                  <p className="text-white let-space-7 fnt-26 text-uppercase fnt-trap mb-3">
                    ORDER ONLINE NOW!
                  </p>
                  <h2 className="title p-0 text-white">
                    Shop. <br /> Refresh. <br /> Repeat.
                  </h2>

                  <div className="col-lg-8 col-12 p-0">
                    <h5 className="text-white fnt-24  pb-0 pt-5 ">
                    There's an army of refreshing juices waiting to be delivered straight to your doorstep
                    </h5>
                    <a href={`${process.env.PUBLIC_URL}/store`} className="btn btn-border-shadow mt-5">
                      <span>Shop now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeFooterContent;
