import Layout from "../components/Layout";
import VSideo from "../components/video";
import Cleanses from "../components/cleanses";
import React from "react";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";
import OwlCarousel from "react-owl-carousel2";
import logoWhite from "../assets/img/logo-white-icon.png";
import whyCleanses from "../assets/img/why-cleanses.jpg";
import package1 from "../assets/img/package-1.png";
import sinPro from "../assets/img/sin-por.png";
import timeline1 from "../assets/img/timeline-1.jpg";
import timeline2 from "../assets/img/timeline-2.jpg";
import timeline3 from "../assets/img/timeline-3.jpg";
import TestSlider from "../components/TestSlider";
import HomeMobileSlider from "../components/HomeMobileSlider";
import HomeDesktopContent from "../components/HomeDesktopContent";
import HomeFooterContent from "../components/HomeFooterContent";


import $ from "jquery";


import { Helmet } from "react-helmet";

//WooCommerce.getAsync('products').then(function(result) {

//console.log(JSON.parse(result.toJSON().body));
//return JSON.parse(result.toJSON().body);
//});

class Clense extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
 
    return (
      <Layout>

<Helmet>
          {" "}
          <title>Cleanses | Refreshing Ayurwedic Wellness | RAW</title>
          <meta name="description" content="We ensure our cleanse programs are nutritinally balanced with the help of ayurvedic doctors and nutritionist." />
          <link rel="canonical" href="https://rawsrilanka.com/our-cleanses" />

        </Helmet>
        <div className="banner-area inner aboutRaw">
        
          <div className="container">
            <div className="">
              <div className="item">
                <div className="row col-lg-11 col-12 mx-auto">
                  <div className="col-lg-6 col-md-6 align-self-center">
                    <div className="banner-inner">
                      <h2 className="text-white">
                        What is a <br />
                        Juice Cleanse?
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-area pd-top-105 pd-bottom-120">
          <div className="container">
            <div className="section-title text-center col-md-10 col-12 mx-auto">
              <h2 className="title ">
                Are unhealthy snacks or take-away foods increasingly finding
                their way into your daily diet?
              </h2>

              <p className="sub-title">
                Juice cleansing is when you only consume fresh raw fruit and
                vegetable juices, herbal teas, water and vegetable soups, for a
                short period, usually between 1-7 days. During a juice cleanse
                you abstain from eating solid foods, black tea or coffee, milk
                and dairy products, alcohol, sugar, soft drinks, and whey based
                protein powder.
              </p>

              <p className="sub-title">
                {" "}
                It is recommended that you seek advice from a health care
                professional if you are planning on going on a juice fast for
                longer than a week, or if you have any medical conditions. Juice
                fasting is not recommended for pregnant or breastfeeding women.
              </p>
            </div>
            <div className="row">
            <MediaQuery minDeviceWidth={768}>
              <div className="col-lg-12">
                <div className="thumb">
                  <img src={whyCleanses} />
                </div>
              </div>

              
              <div className="align-self-center col-lg-4 position-absolute pl-5">
                <div className=" mt-5 mt-lg-0 pl-lg-5">
                  <h2 className="title p-0 text-white">Why Cleanse?</h2>
                  <p className="pb-2 text-white">
                    Juice cleansing gives your body a much needed break from
                    toxins that are consumed daily.
                  </p>
                </div>
              </div>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={767}>
              <div className="col-lg-12">
                <div className="thumb">
                  <img src={whyCleanses} />
                </div>
              </div>

              
              <div className="align-self-center col-lg-4 position-relative ">
                <div className=" mt-5 mt-lg-0 pl-lg-5">
                  <h2 className="title p-0 text-dark">Why Cleanse?</h2>
                  <p className="pb-2 text-dark">
                    Juice cleansing gives your body a much needed break from
                    toxins that are consumed daily.
                  </p>
                </div>
              </div>
              </MediaQuery>
            </div>
          </div>
        </div>

        <div className="about-area ">
          <div className="container position-relative take-top1 padd-btm-80">
            <div className="row">
              <div className="col-lg-6 align-self-start">
                <div className=" mt-lg-0">
                  <p className="pb-2 text-justify pr-lg-3 pr-0">
                    Juice cleansing can help assist our body’s detoxification
                    and elimination systems, to help clear toxins from the body.
                    Daily we are exposed to an array of toxins through the food
                    we eat, the air we breathe, the water we drink, chemicals we
                    put on our skin, plastics we pack our food in and from
                    pharmaceutical drugs we take. If our diet lacks certain
                    nutrients, our natural detoxification processes might be
                    impaired too. Our juice cleanse helps saturate the body with
                    important vitamins, minerals, and antioxidants, along with
                    protein that provides adequate amino acids essential for
                    liver detoxification.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 align-self-start">
                <div className=" mt-lg-0">
                  <p className="pb-2 text-justify">
                    Juices are easy for the body to digest and their nutrients
                    readily absorbed. You are also able to consume much larger
                    quantities of nutritious fruits and vegetables via juicing
                    than if you were able to eat them whole, so juice fasting
                    can help deliver higher levels of vitamins and minerals to
                    your cells.
                  </p>

                  <p className="pb-2 text-justify">
              
                    If you are experiencing symptoms such as tiredness,
                    bloating, wind, constipation, frequent colds and flu, skin
                    problems, weight gain, and sugar cravings, a juice cleanse
                    could be just the thing you need to boost your health.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      

        <div className="our-coffee-area pd-bottom-120 position-relative timelinesec">
          <div className="container position-relative take-top1">
            <div className="section-title text-center mb-lg-5 mb-3">
              <h2 className="title">How to cleanse?</h2>
            </div>

            <div className="row bg-watersplash justify-content-center">
              <div className="col-lg-5 px-0 padd-v-60">
                <div className="thumb">
                  <img src={timeline1} alt="img" />
                </div>
              </div>
              <div className="col-lg-1">
                <div className="limeline"></div>
              </div>
              <div className="col-lg-5 align-self-start px-lg-0 padd-v-80">
                <div className=" mt-5 mt-lg-0">
                  <h3 className="title col-8 p-0 text-yellow">Before</h3>
                  <p className="pb-2">
                  Before you start your cleanse we suggest that you ease your way to the cleansing process by avoiding meats and poultry, refined starches (bread, white rice and pasta), dairy products, sugar, alcohol and nicotine, at least 3-5 days before you start the cleanse.
                  </p>

                  <p className="pb-2">
                  You should try replacing those with fresh wholesome foods like fruits, fresh salads, vegetables soups, starchy vegetables, avocados and other healthy wholesome food choices.
                  </p>
                </div>
              </div>
            </div>

            <div className="row bg-watersplash justify-content-center">
              <div className="col-lg-5 order-lg-3 px-0 padd-v-60">
                <div className="thumb">
                  <img src={timeline2} alt="img" />
                </div>
              </div>
              <div className="col-lg-1 order-lg-2">
                <div className="limeline"></div>
              </div>
              <div className="col-lg-5 align-self-start px-lg-0 padd-v-80 order-lg-1 ">
                <div className=" mt-5 mt-lg-0">
                  <h3 className="title col-8 p-0 text-yellow">During</h3>
                  <p className="pb-2">
                  Depending on how healthy your normal diet and lifestyle is you may experience some detox symptoms during your juice cleanse. If you drink coffee or alcohol regularly, you may experience some headaches or other withdrawal symptoms.

                  </p>

                  <p className="pb-2">
                    {" "}
                    If you consume a lot of sugary or junk foods you can expect more symptoms than someone who has a very clean and healthy diet. Some common detox symptoms that you may experience, usually early on in a cleanse, include tiredness, headaches, mood swings, cravings, constipation or diarrhoea, and excess wind.

                  </p>

                  <p className="pb-2">
                    {" "}
                    Chia seeds are added to some of our juices for extra fibre. This will ensure that your bowels are working well, which will prevent constipation and toxins from reabsorbing back into the body. It is also recommended that you take a probiotic supplement daily during your cleanse to promote healthy digestion and help boost your immune system.
                  </p>

                  <p className="pb-2">
                    {" "}
                    All RAW juice cleanse programs have been designed in collaboration with a nutritionist and ayurvedic doctor, who ensures our programs are nutritionally balanced.

                  </p>

                  <p className="pb-2">
                    {" "}
                    We are here to support you all the way, so please do contact us if you want more information or have any questions.
                  </p>
                </div>
              </div>
            </div>

            <div className="row bg-watersplash justify-content-center">
              <div className="col-lg-5 px-0 padd-v-60">
                <div className="thumb">
                  <img src={timeline3} alt="img" />
                </div>
              </div>
              <div className="col-lg-1">
                <div className="limeline"></div>
              </div>
              <div className="col-lg-5 align-self-start px-lg-0 padd-v-80">
                <div className=" mt-5 mt-lg-0">
                  <h3 className="title col-8 p-0 text-yellow">After</h3>
                  <p className="pb-2">
                    After your cleanse you should go back to eating a healthy
                    well-balanced diet. Don’t get stuck into unhealthy foods and
                    drinks as soon as you finish and undo all of your progress.
                    Use this cleanse as a stepping-stone to a better eating
                    habits and health.
                  </p>

                  <p className="pb-2">
                    Ease yourself slowly back into eating solid meals again, by
                    eating smaller healthy meals, and chew your food properly to
                    assist with proper digestion.
                  </p>

                  <p className="pb-2">
                    Try to limit your intake of wheat products and include more
                    gluten-free grains, and ease off the sugars, refined or
                    sugary foods, coffee, alcohol and fried foods. Keep juicing
                    and include at least 2 juices daily in your diet. If you
                    would like a more detailed plan on how to continue eating
                    healthy after the cleanse we are always available for
                    advice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-coffee-area pt-5 pd-bottom-120 water-bubble top-only position-relative">
          <div className="container padd-tp-150 position-relative take-top1">
            <div className="section-title text-center">
              <h2 className="title">Pick your cleanse package</h2>
              <p className="sub-title">
                A healthy outside starts from the inside.
              </p>
            </div>

         <Cleanses/>

            
          </div>
        </div>
      </Layout>
    );
  }
}

export default Clense;
