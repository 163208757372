import Layout from "../components/Layout";
import VSideo from "../components/video";
import React from "react";
import { NavLink, Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";
import OwlCarousel from "react-owl-carousel2";
import logoWhite from "../assets/img/logo-white-icon.png";
import Blogclenses from "../components/BlogClenses";
import Blogjuices from "../components/BlogJuices";
import Blog1 from "../assets/img/blog-1.jpg";
import Blog2 from "../assets/img/blog-2.jpg";
import ProSin1 from "../assets/img/pro-sin-1.png";
import package1 from "../assets/img/package-1.png";
import SinPro from "../assets/img/sin-por.png";
import TestSlider from "../components/TestSlider";
import HomeMobileSlider from "../components/HomeMobileSlider";
import HomeDesktopContent from "../components/HomeDesktopContent";
import HomeFooterContent from "../components/HomeFooterContent";

import $ from "jquery";

import { Helmet } from "react-helmet";

class Blog extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <Layout>
       <Helmet>
          {" "}
          <title>Blog | Refreshing Ayurwedic Wellness | RAW </title>
          <meta name="description" content="Learn and read  about the work process of corporate companies on creating  organic farming through our blog." />
          <link rel="canonical" href="https://rawsrilanka.com/blog" />
        </Helmet>
<div className="banner-area inner aboutRaw" >
        <div className="container">
            <div className="">
                <div className="item">
                    <div className="row col-lg-11 col-12 mx-auto">
                        <div className="col-lg-6 col-md-6 align-self-center">
                            <div className="banner-inner">

                                <h2 className="text-white">Blog </h2>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>


    </div>
    <div className="main-blog-area pd-top-150 pd-bottom-150">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-7 col-12">
                    <div className="single-blog-card">
                        <div className="thumb">
                        <NavLink
                      strict
                      exact
                      to={`${process.env.PUBLIC_URL}/organic-fruits-surprising-benefits-and-facts`}
                      key={`${process.env.PUBLIC_URL}/organic-fruits-surprising-benefits-and-facts`}
                      className=" "
                      onClick={this.hideMenu}
                    >
                            <img src={Blog1} alt="blog"/>
                            <span className="date">January 3, 2021</span>
                            </NavLink>
                        </div>
                        <div className="blog-content">
                            <h4 className="text-dark">
                                <NavLink
                      strict
                      exact
                      to={`${process.env.PUBLIC_URL}/organic-fruits-surprising-benefits-and-facts`}
                      key={`${process.env.PUBLIC_URL}/organic-fruits-surprising-benefits-and-facts`}
                      className="nav-link "
                      onClick={this.hideMenu}
                    >Organic Fruits: Surprising Benefits And Facts </NavLink>
                    </h4>
                            <p>Organic farming is the only way that you still can experience the real world. If you are experiencing an organic lifestyle then</p>
                        </div>
                    </div>
                    <div className="single-blog-card">
                        <div className="thumb">
                        <NavLink
                      strict
                      exact
                      to={`${process.env.PUBLIC_URL}/everything-you-need-to-know-about-organic`}
                      key={`${process.env.PUBLIC_URL}/everything-you-need-to-know-about-organic`}
                      className=" "
                      onClick={this.hideMenu}
                    >
                        <img src={Blog2} alt="blog"/>
                           
                            <span className="date">January 3, 2021</span>
                            </NavLink>
                        </div>
                        <div className="blog-content">
                            <h4 className="text-dark"><NavLink
                      strict
                      exact
                      to={`${process.env.PUBLIC_URL}/everything-you-need-to-know-about-organic`}
                      key={`${process.env.PUBLIC_URL}/everything-you-need-to-know-about-organic`}
                      className="nav-link "
                      onClick={this.hideMenu}
                    >Everything You Need To Know About Organic</NavLink></h4>
                            <p>Organic farming is the only way that you still can experience the real world. If you are experiencing an organic lifestyle then</p>
                        </div>
                    </div>
                   
                </div>
        
                <div className="col-lg-5 col-12">
                    <div className="td-sidebar mt-5 mt-lg-0">
                                    
                        <div className="widget widget-recent-post">                            
                            <h4 className="widget-title text-dark">Recent Juices</h4>
                            <Blogjuices/>
                         
                        </div>
                        <div className="widget widget_catagory">
                            <h4 className="widget-title text-dark">Juice Cleanse</h4>    
                                                     
                            <ul className="catagory-items">
                            <Blogclenses/>
                             
                                
                                
                                
                                
                                
                                
                            </ul>
                        </div> 
                        
                        
                    </div>
                </div>
           
            </div>
        </div>
    </div>
      </Layout>
    );
  }
}

export default Blog;
