import Layout from "../components/Layout";
import VSideo from "../components/video";
import React from "react";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";
import OwlCarousel from "react-owl-carousel2";
import logoWhite from "../assets/img/logo-white-icon.png";
import Blog1 from "../assets/img/blog-1.jpg";
import Blog2 from "../assets/img/blog-2.jpg";
import ProSin1 from "../assets/img/pro-sin-1.png";
import package1 from "../assets/img/package-1.png";
import SinPro from "../assets/img/sin-por.png";
import TestSlider from "../components/TestSlider";
import HomeMobileSlider from "../components/HomeMobileSlider";
import HomeDesktopContent from "../components/HomeDesktopContent";
import HomeFooterContent from "../components/HomeFooterContent";
import $ from "jquery";

class BlogSingle extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <Layout>
      <div className="banner-area inner aboutRaw" >
        <div className="container">
            <div className="">
                <div className="item">
                    <div className="row col-lg-11 col-12 mx-auto">
                        <div className="col-lg-12 col-md-12 align-self-center">
                            <div className="banner-inner">

                                <h2 className="text-white">Blog</h2>
                                <h5 className="text-white">Organic Fruits: Surprising Benefits And Facts</h5>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>


    </div>

    <div className="main-blog-area  pd-bottom-150 mt-5" >
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-12 mx-auto">
                    <div className="single-blog-card">
                        <div className="thumb">
                            <img src={Blog1} alt="blog" className="w-100"/>
                            <span className="d-block fnt-13 font-italic pt-4 tex-cen text-center">January 3, 2021
</span>
                        </div>
                        <div className="blog-content">
                            
                        <br/><br/>

                        Organic farming is the only way that you still can experience the real world. If you are experiencing an organic lifestyle then you are running the best life in this modern world. So on this page, we are gonna see the work process of corporate companies in creating organic farming. So without any further ado here we go with the work process in organic farming.
                        <br/><br/>

In Organic farming, the first step is Sorting and cleaning. Sorting is nothing but from the collected products that need to separate based on our plans and need to check they are 100% organic. Then cleaning, it means to clean the sorted products to ensure that all the products are of supreme quality.
<br/><br/>
The next step is destoning it is nothing but removing the stones and other unnecessary materials from grains and cereals. Then need to carry out the roasting or sterilizing process. This step is carried out to increase the aromas and also to include the beneficial nutrients. All the above-mentioned steps are needed to be allowed to have a perfect output since that is the base for your organic farming.
<br/><br/>
Next, let’s start the grinding process. the name says that the grinding is going to happen in this process the products are grounded and blended to bring out their fine quality & flavours. After the grinding process labeling process is carried out. In the grounded process the labeling process is carried out for separating the brands which help in identifying the products easily.
<br/><br/>
Once the labeling process is carried out we need to focus on the packaging. All the products are packed perfectly so that their oils and aroma remain intact, then they’re dispatched. Finally, they are shipped so that they will reach a particular destination to the customer. All the above mention are the general work process of an organization in organic farming.
                        </div>
                    </div>
                    
                   
                </div>
              
            </div>
        </div>
    </div>

  
      </Layout>
    );
  }
}

export default BlogSingle;
