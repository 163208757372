import React, { Component } from "react";
import $ from "jquery";
import WooCommerceAPI from "woocommerce-api";
import sinPro from "../assets/img/sin-por.png";
import package1 from "../assets/img/package-1.png";
import ReactHtmlParser from "react-html-parser";

class CleansBlogitems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    const WooCommerce = new WooCommerceAPI({
      url: "https://rawsrilanka.com/store/index.php/",
      consumerKey: "ck_7c0b2fb19071bc97f62668e1caeacd3a6fb4ca9e",
      consumerSecret: "cs_460fb53ec8b6ab210efd94a553d53a1f8103d430",
      wpAPI: true,
      version: "wc/v1",
    });

    WooCommerce.getAsync("products?category=84").then((data) =>
      this.setState({
        isLoaded: true,
        items: JSON.parse(data.toJSON().body),
      })
    );
  }

  render() {
    const { items, isLoaded } = this.state;
    if (!isLoaded) {
      return <div>Loading ... </div>;
    } else {
      return (
        <div >
          {items.map((value, index) => {
            return (
              <li><div className="bg-white row bx-shadowl mt-5 m-0">

              <div className="align-self-center col-lg-4">
                  <div className="bg-watersplash thumb"><img src={value.images[0].src} alt="blog"/></div>

              </div>

              <div className="col-lg-8 align-self-start">
                  <h6 className="text-dark  py-3 m-0">{value.name}</h6>

                  <div className="row bg-yellow align-items-center p-2 ribon-1">
                      <div className="col-lg-12">

                          <h5 className="m-0"><span className="fnt-18 pr-2"></span> {ReactHtmlParser(value.price_html)}</h5>
                          <span className="fnt-12 text-uppercase text-white">
                          {ReactHtmlParser(value.product_benifits)}
                          </span>

                      </div>
                    
                      
                  </div>
                  
                  <hr className="m-0"/>
                  <div className="row  py-3">
                      <div className="col-lg-12">
                          <h6 className="text-dark ">What you get</h6>

                          
                                  <div className="col-md-12 col-12  p-0 pb-2">
                                      <div className="row align-items-center bordergrid">

                                     {value.chained_items.map((option, i) => (
<div>

    <div className="row m-0 align-items-center"> 
    {Object.values(option).slice(0, 3).map((items, i) => (


<div className="col-md-6 col-12  p-0 pb-2">
    <div className="row align-items-center bordergrid ">
        <div className="col-3 p-0">
            <div className="thumb"><img src={items.product_img} alt="img"/></div>
        </div>
        <div className="col-9 px-2 line-hght-18">
            <span className="d-block fnt-12">     {items.product_name}</span>
            <span className="font-weight-bold fnt-14">0{items.unit} nos</span>
        </div>
    </div>
    </div>

         

))}
        
        <div className="col-md-6 col-12  p-0 pb-2">
          <h4 className="text-center text-dark m-0">{Object.values(option).length-3} +</h4>
          <h6 className="text-center text-dark m-0"><a href={value.permalink} className="p-0">Read more</a></h6>
                                              
                                              </div>

    
    </div>



</div>



))}
                                          
                                      </div>
                                      </div>

                                     

                                       

                          </div>
                      </div>
                  </div>



              
          </div>
</li>
            );
          })}
        </div>
      );
    }
  }
}

export default CleansBlogitems;
