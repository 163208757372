import React, { Component } from "react";
import $ from "jquery";
import OwlCarousel from "react-owl-carousel2";
import testImage from "../assets/img/testimoinal-1.jpg";

class PartnersSlider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const testimoinals = [
      {
        description:
          "Ideal product for adults and kids to get in their daily fruit, vegetable and leafy green intake ",
        title: "Bilal Yusuf",
      },
      {
        description:
          "A bottle of RAW juice every morning not only fills you up, it nourishes you and cleanses you for the rest of the day ",
        title: "Tania Ajiboye",
      },
      {
        description:
          "I suffered from pretty bad eczema and my skin cleared up after the RAW one-day cleanse….no more bloating and I felt very light!",
        title: "Suveksha Botejue",
      },
      {
        description:
          "I absolutely love RAW…I have tried the One day and Two day cleanse and thoroughly loved it….it was like Christmas every 2.5hrs as I eagerly anticipated each jar and the flavours",
        title: "Rozana Mahajuodeen",
      },
      {
        description:
          "Improvement in my skin, acne has reduced and an allergy I was suffering with disappeared after the RAW green cleanse",
        title: "Varuni",
      },
      {
        description:
          "Thanks to RAW I can fit into my old clothes!",
        title: "Menaka",
      },
      {
        description:
          "It was absolutely amazing! I am feeling so light….gave me a good start for healthy eating. Great concept!",
        title: "Anushka",
      },
      {
        description:
          "Omg I loved it! Like you can feel your system go all clean clean clean! Amazing job!",
        title: "Shazna",
      },
      {
        description:
          "I got the cleanse for my father and he kind of got into control with his food habits ever since the cleanse! This was something he struggled with before",
        title: "Aarthi",
      },
      {
        description:
          "We took the 3 day cleanse and my husband lost 4.5 Kg!!! he absolutely loves RAW’s green juices!",
        title: "",
      },
      {
        description:
          "I have struggled with acne….today when I looked in the mirror my face has completely cleared up! Thank you so much! Love your products they taste so good! The green juices I make taste crap but these are so good!",
        title: "Dr Natalie Cooke",
      },
      {
        description:
          "Tried a green cleanse for the first time! Woke up feeling so much fresher, lighter and glowing skin! Service was spot on. Keep it up RAW and Ronali – ",
        title: "Yadushika",
      },
    ];

 

    const options = {
      nav: false,
      loop: true,
      margin: 30,
      autoplay: true,
      dots: true,
      navText: [
        ' <a className="left carousel-control " data-slide="prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 407.4 407.4" fill="#fff" width="30"><path d="M316 21L295 0 92 204l203 203 21-21-182-182z"/></svg><span className="sr-only">Previous</span> </a> ',
        '<a className="right carousel-control "  data-slide="next"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 407.4 407.4" fill="#fff" width="30"><path d="M113 0L92 21l182 183L92 386l21 21 203-203z"/></svg> <span className="sr-only">Next</span> </a>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        450: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 3,
        },
      },
    };

    const events = {
      onInitialized: function (event) {
        var idx = event.item.index;
        $(".owl-item").eq(idx).addClass("big");
        $(".owl-item")
          .eq(idx - 1)
          .addClass("medium");
        $(".owl-item")
          .eq(idx + 1)
          .addClass("medium");
      },
      onChanged: function (event) {
        var idx = event.item.index;
        $(".owl-item").removeClass("big");
        $(".owl-item").removeClass("medium");
        $(".owl-item").eq(idx).addClass("big");
        $(".owl-item")
          .eq(idx - 1)
          .addClass("medium");
        $(".owl-item")
          .eq(idx + 1)
          .addClass("medium");
      },
    };

    return (
      <OwlCarousel
        className={"owl-theme owl-carousel banner-slider-2"}
        options={options}
        events={events}
      >
        {testimoinals.map((value, index) => {
          return (
            <div className="item mt-5 pt-5 bdr-tp-5-white">
              <div className="row col-12 mx-auto p-0">
                <div className="col-lg-1 col-2 p-0 align-self-start order-lg-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 108.23 95.39"
                  >
                    <defs></defs>
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          className="cls-1"
                          d="M47.06,54.75V95.39H0V53.47C-.42,25.24,16.26,0,44.92,0V23.53c-15.4,0-20.53,9.83-22.24,19.67C21.4,50.05,15.41,54.32,10.27,59l6.42,7.27c5.13-5.13,9.84-11.55,16.68-11.55Zm61.17,0V95.39H61.18V53.47C61.18,25.24,77.43,0,106.52,0V23.53c-15.4,0-20.53,9.83-22.25,19.67C83,50.05,77,54.32,71.87,59l6.42,7.27C83.42,61.17,88.12,54.75,95,54.75Z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                {/* <div className="col-lg-4 col-md-6 order-lg-1">
                  <div className="thumb text-right">
                    <img src={testImage} alt="img" />
                  </div>
                </div> */}

                <div className="col-lg-10 col-md-10 align-self-center order-lg-3">
                  <div className="banner-inner">
                    <h2 className="text-white fnt-24 pb-3 pt-5 ">
                      {value.description}
                    </h2>
                    <h2 className="text-white fnt-20  pb-3 fnt-wgt-rgl">
                      - {value.title}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    );
  }
}

export default PartnersSlider;
