import Layout from "../components/Layout";
import VSideo from "../components/video";
import React from "react";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";
import OwlCarousel from "react-owl-carousel2";
import logoWhite from "../assets/img/logo-white-icon.png";
import juiceMug from "../assets/img/juice-mug.jpg";
import Contactus from "../assets/img/contact-us.jpg";
import TestSlider from "../components/TestSlider";
import HomeMobileSlider from "../components/HomeMobileSlider";
import HomeDesktopContent from "../components/HomeDesktopContent";
import HomeFooterContent from "../components/HomeFooterContent";
import $ from "jquery";

import { Helmet } from "react-helmet";

class Index extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <Layout>
           <Helmet>
          {" "}
          <title>Contact Us | Refreshing Ayurwedic Wellness | RAW</title>
          <meta name="description" content="To know more about RAW and the different products we offer contact or visit us now." />
          <link rel="canonical" href="https://rawsrilanka.com/contact-us" />
        </Helmet>
        <div className="banner-area inner aboutRaw" >
        <div className="container">
            <div className="">
                <div className="item">
                    <div className="row col-lg-11 col-12 mx-auto">
                        <div className="col-lg-6 col-md-6 align-self-center">
                            <div className="banner-inner">

                                <h2 className="text-white">Contact <br/>
                                    Raw</h2>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>


    </div>
    <div className="contact-area pd-top-105 pd-bottom-150">
        <div className="container">
            <div className="section-title text-center">
                <h2 className="title">Get in touch</h2>
            </div>
            <div className="row">
                <div className="col-lg-5 col-md-6">
                    <div className="thumb mb-5 mb-md-0"><img src={Contactus} alt="img"/></div>
                </div>
                <div className="col-lg-7 col-md-6 align-self-center">
                    <form className="contact-form-inner">
                        <div className="single-input-inner"><input type="text" placeholder="Your Name"/></div>
                        <div className="single-input-inner"><input type="text" placeholder="Phone Number"/></div>
                        <div className="single-input-inner"><input type="text" placeholder="Email"/></div>
                        <div className="single-input-inner"><textarea rows="5" placeholder="Message"></textarea></div>
                        <div className="btn-wrap"><a className="btn btn-border-shadow padd-h-25 text-uppercase" href="#">Send Message</a></div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15845.070435761543!2d79.8917883!3d6.8584955!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdb6860f5549c7b14!2sRAW%20Organic%20Handcrafted%20Juices!5e0!3m2!1sen!2slk!4v1625898481183!5m2!1sen!2slk" width="100%" height="450"  allowfullscreen="" loading="lazy"></iframe>

     
      </Layout>
    );
  }
}

export default Index;
