import React, { Component } from "react";
import $ from "jquery";
import WooCommerceAPI from "woocommerce-api";
import sinPro from "../assets/img/sin-por.png";
import package1 from "../assets/img/package-1.png";
import ReactHtmlParser from "react-html-parser";

class CleansBlogitems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    const WooCommerce = new WooCommerceAPI({
      url: "https://rawsrilanka.com/store/index.php/",
      consumerKey: "ck_7c0b2fb19071bc97f62668e1caeacd3a6fb4ca9e",
      consumerSecret: "cs_460fb53ec8b6ab210efd94a553d53a1f8103d430",
      wpAPI: true,
      version: "wc/v1",
    });

    WooCommerce.getAsync("products?category=83").then((data) =>
      this.setState({
        isLoaded: true,
        items: JSON.parse(data.toJSON().body),
      })
    );
  }

  render() {
    const { items, isLoaded } = this.state;
    if (!isLoaded) {
      return <div>Loading ... </div>;
    } else {
      return (
        <ul>
          {items.slice(0, 3).map((value, index) => {
            return (
               
                                
                <li className="bx-shadowl p-2 mt-2">
                    <a href={value.permalink} className="media row mb-0">
                        <div className=" col-3 m">
                            <img src={value.images[0].src} alt="blog"/>
                        </div>
                        <div className="media-body col-8">
                            <h5 className="title text-dark fnt-20 mb-1">{value.name}</h5>
                            <div className="post-info"><span>LKR  {ReactHtmlParser(value.price_html)}</span></div>                             
                        </div>
                    </a>
                </li>
              
        
            );
          })}
        </ul>
      );
    }
  }
}

export default CleansBlogitems;
