import React from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import btlAngelled from "../assets/img/btl-angelled.png";
//import Cartitems from "../components/cart";
import $ from "jquery";

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { em: 'jevon@xdigital.solutions' }; 
const options = {
  autoConfig: true, 
  debug: false, 
};
ReactPixel.init('416309083336886', advancedMatching, options);

ReactPixel.pageView(); 


ReactGA.initialize('UA-211459824-2');

ReactGA.pageview(window.location.pathname + window.location.search);



class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        show: false
    }
    this.hideMenu = this.hideMenu.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.hide = this.hide.bind(this);

}

hideMenu(e) {
  $(".burger").removeClass("clicked");
  $(".navbar-area .navbar-collapse").removeClass("sopen");
  $(".ba-navbar-menu nav").removeClass("ba-navbar-show show", "linear");
  this.setState({ show: false });
}

toggleShow() {
  $(".burger").removeClass("clicked");
  $(".navbar-area .navbar-collapse").removeClass("sopen");
  $(".ba-navbar-menu nav").removeClass("ba-navbar-show show", "linear");
  this.setState({ show: !this.state.show });
}

hide(e) {
  $(".burger").removeClass("clicked");
  $(".navbar-area .navbar-collapse").removeClass("sopen");
  $(".ba-navbar-menu nav").removeClass("ba-navbar-show show", "linear");
  this.setState({ show: false });
}


  componentDidMount() {

   
   //   ReactGA.initialize('UA-211459824-2');
    //  ReactGA.pageview(window.location.pathname + window.location.search);


    $(window).on("scroll", function () {
      var b = $(".navbar-area");
      if ($(window).scrollTop() >= 1) {
        b.addClass("navbar-area-fixed");
      } else {
        b.removeClass("navbar-area-fixed");
      }
      var c = $(".back-to-top");
      if ($(window).scrollTop() > 1000) {
        c.fadeIn(1000);
      } else {
        c.fadeOut(1000);
      }
    });

    $(document).on(
      "click",
      ".navbar-area .navbar-nav li.menu-item-has-children>a",
      function (n) {
        n.preventDefault();
      }
    );
    $(".navbar-area .menu").on("click", function () {
      $(this).toggleClass("open");
      $(".navbar-area .navbar-collapse").toggleClass("sopen");
    });
    if ($(window).width() < 992) {
      $(".in-mobile").clone().appendTo(".sidebar-inner");
      $(".in-mobile ul li.menu-item-has-children").append(
        '<i class="fas fa-chevron-right"></i>'
      );
      $('<i class="fas fa-chevron-right"></i>').insertAfter("");
      $(".menu-item-has-children a").on("click", function (n) {
        $(this).siblings(".sub-menu").animate({ height: "toggle" }, 300);
      });
    }
    var h = $(".menu-toggle");
    var g = $(".navbar-nav");
    h.on("click", function () {
      if (h.hasClass("is-active")) {
        g.removeClass("menu-open");
      } else {
        g.addClass("menu-open");
      }
    });

    if ($(".menu-bar").length) {
      $(".menu-bar").on("click", function () {
        $(".ba-navbar").toggleClass("ba-navbar-show", "linear");
      });
      $("body").on("click", function (n) {
        if (
          !$(n.target).closest(".menu-bar").length &&
          !$(n.target).closest(".ba-navbar").length
        ) {
          $(".ba-navbar").removeClass("ba-navbar-show");
        }
      });
      $(".menu-close").on("click", function () {
        $(".ba-navbar").toggleClass("ba-navbar-show", "linear");
      });
    }

    if ($(".menu-bar-nav").length) {
      $(".menu-bar-nav").on("click", function () {
        $(".ba-navbar-menu nav").toggleClass("ba-navbar-show show", "linear");
      });
      $("body").on("click", function (n) {
        if (
          !$(n.target).closest(".menu-bar-nav").length &&
          !$(n.target).closest(".ba-navbar-menu").length
        ) {
          $(".ba-navbar-menu nav").removeClass("ba-navbar-show show");
        }
      });
      $(".menu-close-nav").on("click", function () {
        $(".ba-navbar-menu nav").toggleClass("ba-navbar-show show", "linear");
      });
    }

    $(".burger").click(function () {
      $(".burger").toggleClass("clicked");
      $(".navbar-area .navbar-collapse").toggleClass("sopen");
    });

    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/616d37da86aee40a573716e0/1fi9a4cha';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();

(function() {
  const __insp = window.__insp || [];
  __insp.push(['wid', 454323835]);
  var ldinsp = function(){
  if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=454323835&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
  setTimeout(ldinsp, 0);
  })();


  }
  render() {
    
    return (
      <div>
        <div className="ba-navbar">
          <div className="menu-close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 492 492"
              fill="#fff"
              width="25"
            >
              <path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z" />
            </svg>
          </div>
          <div className="ba-navbar-inner">
            <h3>Check out</h3>

           

          </div>
        </div>
        <div className="navbar-area style-two">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid nav-container">
              <div className="responsive-mobile-menu">
                <button
                  className="menu toggle-btn d-block d-lg-none"
                  data-target="#coffee_main_menu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-left"></span>
                  <span className="icon-right"></span>
                </button>
              </div>
              <div className="logo">
                <NavLink
                  strict
                  exact
                  to={`${process.env.PUBLIC_URL}/`}
                  key={`${process.env.PUBLIC_URL}/`}
                  className="nav-link "
                  onClick={this.hideMenu}
                >
                  <img src={logo} />
                </NavLink>
              </div>
              <div className="collapse navbar-collapse" id="coffee_main_menu">
                <ul className="navbar-nav menu-open">
                  <li>
                    <NavLink
                      strict
                      exact
                      to={`${process.env.PUBLIC_URL}/about-raw`}
                      key={`${process.env.PUBLIC_URL}/about-raw`}
                      className="nav-link "
                      onClick={this.hideMenu}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      strict
                      exact
                      to={`${process.env.PUBLIC_URL}/our-cleanses`}
                      key={`${process.env.PUBLIC_URL}/our-cleanses`}
                      className="nav-link "
                      onClick={this.hideMenu}
                    >
                      Our Cleanses
                    </NavLink>
                  </li>

                  <li>
                    <a
                      href={`${process.env.PUBLIC_URL}/store`}
                      className="btn btn-border-shadow padd-h-50"
                    >
                      Order Now
                    </a>
                  </li>
                </ul>

                <div className="nav-right-part nav-right-part-desktop d-flex">
                  <a className="burger menu-bar-nav" href="javascript:void(0);">
                    <span></span>
                  </a>
                </div>

                <div className="ba-navbar-menu">
                  <nav className="ba-navbar-inner bg-watersplash">
                    <div className="align-items-center h-100 row">
                      <div className="col-md-6">
                        <ul className="main">
                          <li>
                          <NavLink
                      strict
                      exact
                      to={`${process.env.PUBLIC_URL}/`}
                      key={`${process.env.PUBLIC_URL}/`}
                      className="nav-link "
                      onClick={this.hideMenu}
                    >
                     Home
                    </NavLink>
                          </li>
                          <li>
                          <NavLink
                      strict
                      exact
                      to={`${process.env.PUBLIC_URL}/about-raw`}
                      key={`${process.env.PUBLIC_URL}/about-raw`}
                      className="nav-link "
                      onClick={this.hideMenu}
                    >
                     Our Story
                    </NavLink>
                           
                          </li>

                          <li>
                          <NavLink
                      strict
                      exact
                      to={`${process.env.PUBLIC_URL}/our-cleanses`}
                      key={`${process.env.PUBLIC_URL}/our-cleanses`}
                      className="nav-link "
                      onClick={this.hideMenu}
                    >
                     Juice Cleanses
                    </NavLink>
                           
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/store`}  onClick={this.hideMenu}>
                              Store
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://rawsrilanka.com/store/my-account/"
                              className="nav-link "
                            >
                              - My Account
                            </a>
                          </li>
                          <li>
                          <NavLink
                      strict
                      exact
                      to={`${process.env.PUBLIC_URL}/blog`}
                      key={`${process.env.PUBLIC_URL}/blog`}
                      className="nav-link "
                      onClick={this.hideMenu}
                    >
                     Blog
                    </NavLink>
                          </li>
                          <li>
                            <NavLink
                              strict
                              exact
                              to={`${process.env.PUBLIC_URL}/contact-us`}
                              key={`${process.env.PUBLIC_URL}/contact-us`}
                              className="nav-link "
                              onClick={this.hideMenu}
                            >
                              Contact Us
                            </NavLink>
                          </li>

                        
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <img src={btlAngelled} alt="img" className="" />
                      </div>
                    </div>
                  </nav>
                </div>

                <div className="nav-right-part nav-right-part-desktop">
                <a class=" btn-transparent" href="https://rawsrilanka.com/store/my-account/">
                        

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.667 490.667" width="30" height="30" class="" fill="#fff" style={{marginTop:'10px'}}><path d="M245.333 0C110.059 0 0 110.059 0 245.333s110.059 245.333 245.333 245.333 245.333-110.059 245.333-245.333S380.608 0 245.333 0zm0 469.333c-123.52 0-224-100.48-224-224s100.48-224 224-224 224 100.48 224 224-100.48 224-224 224z"></path><path d="M245.333 234.667c-76.459 0-138.667 62.208-138.667 138.667 0 5.888 4.779 10.667 10.667 10.667S128 379.221 128 373.333C128 308.629 180.629 256 245.333 256s117.333 52.629 117.333 117.333c0 5.888 4.779 10.667 10.667 10.667S384 379.221 384 373.333c0-76.458-62.208-138.666-138.667-138.666zM245.333 64c-41.173 0-74.667 33.493-74.667 74.667s33.493 74.667 74.667 74.667S320 179.84 320 138.667 286.507 64 245.333 64zm0 128C215.936 192 192 168.064 192 138.667s23.936-53.333 53.333-53.333 53.333 23.936 53.333 53.333S274.731 192 245.333 192z"></path></svg>
                        </a>
                  <a className="btn btn-transparent menu-bar d-none" href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 450.391 450.391"
                      className="marg-tp-15"
                    >
                      <g xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M143.673 350.322c-25.969 0-47.02 21.052-47.02 47.02 0 25.969 21.052 47.02 47.02 47.02 25.969 0 47.02-21.052 47.02-47.02.001-25.968-21.051-47.02-47.02-47.02zm0 73.143c-14.427 0-26.122-11.695-26.122-26.122s11.695-26.122 26.122-26.122 26.122 11.695 26.122 26.122c.001 14.427-11.695 26.122-26.122 26.122zM342.204 350.322c-25.969 0-47.02 21.052-47.02 47.02 0 25.969 21.052 47.02 47.02 47.02s47.02-21.052 47.02-47.02c0-25.968-21.051-47.02-47.02-47.02zm0 73.143c-14.427 0-26.122-11.695-26.122-26.122s11.695-26.122 26.122-26.122 26.122 11.695 26.122 26.122c.001 14.427-11.695 26.122-26.122 26.122zM448.261 76.037a13.064 13.064 0 0 0-8.359-4.18L99.788 67.155 90.384 38.42C83.759 19.211 65.771 6.243 45.453 6.028H10.449C4.678 6.028 0 10.706 0 16.477s4.678 10.449 10.449 10.449h35.004a27.17 27.17 0 0 1 25.078 18.286l66.351 200.098-5.224 12.016a50.154 50.154 0 0 0 4.702 45.453 48.588 48.588 0 0 0 39.184 21.943h203.233c5.771 0 10.449-4.678 10.449-10.449s-4.678-10.449-10.449-10.449H175.543a26.646 26.646 0 0 1-21.943-12.539 28.733 28.733 0 0 1-2.612-25.078l4.18-9.404 219.951-22.988c24.16-2.661 44.034-20.233 49.633-43.886L449.83 84.917a8.882 8.882 0 0 0-1.569-8.88zm-43.885 109.191c-3.392 15.226-16.319 26.457-31.869 27.69l-217.339 22.465-48.588-147.33 320.261 4.702-22.465 92.473z"
                          fill="#fff"
                          data-original="#000000"
                        />
                      </g>
                    </svg>
                  </a>
                </div>
              
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Nav;
