import React from 'react';
import {Link} from 'react-router-dom'; 


class Shops extends React.Component {

	constructor (props) {
	  super(props);
	  this.state = { 
	  }
	}

 
	//  
	render () { 
 
	  	return (  
		    <div> 
		    	<br /><br /><br /><br /><br />
		    	<h1 className="text-center"> Oops ! Page Not Found ! </h1> <br />
		    </div> 
		)
	}
			

}export default Shops;	 