import React from 'react';
import videolink  from "../assets/img/home-slider.mp4";

class VSideo extends React.Component {
  render() {
    // const opts = {
    //   mute:true,
    //   loop:1,
    //   allowfullscreen :true,
      
    //   playerVars: {
    //     // https://developers.google.com/youtube/player_parameters
    //     autoplay: 1,
    //     mute:1,
    //     playsinline:0,
    //     playlist:'r7STkGUFax0',
    //     controls: 0,
    //     loop:1
    //   },
    // };

    return  <video autoPlay playsInline muted loop src={videolink} />;
  }

}

export default VSideo;
