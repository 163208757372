import React, { Component } from "react";
import $ from "jquery";
import WooCommerceAPI from "woocommerce-api";
import sinPro from "../assets/img/sin-por.png";
import package1 from "../assets/img/package-1.png";
import ReactHtmlParser from "react-html-parser";

import MediaQuery from "react-responsive";

class Cleansitems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    const WooCommerce = new WooCommerceAPI({
      url: "https://rawsrilanka.com/store/index.php/",
      consumerKey: "ck_7c0b2fb19071bc97f62668e1caeacd3a6fb4ca9e",
      consumerSecret: "cs_460fb53ec8b6ab210efd94a553d53a1f8103d430",
      wpAPI: true,
      version: "wc/v1",
    });

    WooCommerce.getAsync("products?category=84").then((data) =>
      this.setState({
        isLoaded: true,
        items: JSON.parse(data.toJSON().body),
      })
    );
  }

  render() {
    const { items, isLoaded } = this.state;
    if (!isLoaded) {
      return <div>Loading ... </div>;
    } else {
      return (
        <div className="container">
          {items.map((value, index) => {
            return (
              <div className="bg-white row bx-shadowl mt-5">
                <div className="col-lg-3 align-self-start">
                  <div className="thumb">
                    <img src={value.images[0].src} alt="img" />
                  </div>
                </div>

                <div className="col-lg-9 align-self-start">
                  <h4 className="text-dark  py-3">{value.name}</h4>
                  <MediaQuery minDeviceWidth={768}>
                  <div className="row bg-yellow align-items-center p-4 ribon-1">
                    <div className="col-lg-7">
                      <h2 className="m-0">
                        <span className="fnt-26 pr-2 d-block">
                          {" "}
                          {ReactHtmlParser(value.price_html)}
                        </span>
                      </h2>
                      <span className="text-white text-uppercase text-lg-right text-center">
                        {ReactHtmlParser(value.product_benifits)}
                      </span>
                    </div>
                    
                    <div className="col-lg-5 text-lg-right text-center pt-lg-0 pt-3">
                      <a
                        href={value.permalink}
                        className="btn btn-border-shadow padd-h-25"
                      >
                        <span>Order now</span>
                      </a>
                    </div>
                  </div>
                  </MediaQuery>

        <MediaQuery maxDeviceWidth={767}>
        <a href={value.permalink} className="row bg-yellow align-items-center p-4 ribon-1">
                    <div className="col-lg-7">
                      <h2 className="m-0">
                        <span className="fnt-26 pr-2 d-block">
                          {" "}
                          {ReactHtmlParser(value.price_html)}
                        </span>
                      </h2>
                      <span className="text-white text-uppercase text-lg-right text-center">
                        {ReactHtmlParser(value.product_benifits)}
                      </span>
                    </div>
                    
                    <div className="col-lg-5 text-lg-right text-center pt-lg-0 pt-3">
                      <span
                        className="btn btn-border-shadow padd-h-25"
                      >
                        <span>Order now</span>
                      </span>
                    </div>
                  </a>


        </MediaQuery>
                  <div className="row  py-4">
                    <div className="col-lg-12">
                      {ReactHtmlParser(value.short_description)}
                    </div>
                  </div>
                  <hr className="m-0" />
                  <div className="row  py-4">
                    <div className="col-lg-12">
                      <h6 className="text-dark ">What you get</h6>

                      <div className="row m-0"> 
                      
                        {value.chained_items.map((option, i) => (

                            <div>

                          
                                <div className="row m-0"> 
                                
                          
                                {Object.values(option).map((items, i) => (


                         <a 
                         href={value.permalink}  
                         className="col-md-3 col-6  p-0"  >
                              <div className="row align-items-center bordergrid">
                                <div className="col-3 p-0">
                                  <div className="thumb">
                                    <img src={items.product_img} alt="img" />
                                  </div>
                                </div>
                                <div className="col-9 px-2 line-hght-18">
                                  <span className="d-block fnt-12">
                               
                                  {items.product_name.split('(')[0]}<br/> {items.product_name.split('(')[1].replace(')', '')}
                                  </span>
                                  <span className="font-weight-bold fnt-12">
                                  0{items.unit} nos
                                  </span>
                                </div>
                              </div>
                            </a>
                                 

))}
                                    

                                
                                </div>
                            


                            </div>
                         
                         
                         
                        ))}


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
}

export default Cleansitems;
