import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//import { play, exit } from './components/Timelines/Timelines'
import Home from './Home';
import AboutUs from './pages/about';
import ourCleanses from './pages/our-cleanses';
import contactUs from './pages/contact-us';
import Blog from './pages/blog';
import Blog1 from './pages/blog-organic-fruits-surprising-benefits-and-facts';
import Blog2 from './pages/everything-you-need-to-know-about-organic';
import NotFound from './NotFound';


class Routes extends Component {


  render() {
    return (
      
          <Route render={({ location }) => {
            const { pathname, key } = location;

           
              
            const onEnter = node => {
     
         
            };

            const onExit = node => {
              
          
            };

            return (
         
               

          
                  <Switch basename={`${process.env.PUBLIC_URL}/`} location={location} path={`${process.env.PUBLIC_URL}/`}>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                    <Route path={`${process.env.PUBLIC_URL}/about-raw`} component={AboutUs} />
                    <Route path={`${process.env.PUBLIC_URL}/our-cleanses`} component={ourCleanses} />
                    <Route path={`${process.env.PUBLIC_URL}/contact-us`} component={contactUs} />
                    <Route path={`${process.env.PUBLIC_URL}/blog`} component={Blog} />
                    <Route path={`${process.env.PUBLIC_URL}/organic-fruits-surprising-benefits-and-facts`} component={Blog1} />
                    <Route path={`${process.env.PUBLIC_URL}/everything-you-need-to-know-about-organic`} component={Blog2} />
                    <Route component={NotFound} />
                    
                    
                  </Switch>
                 
            
            
            )
          }} />
  
    )
 
  }
}
export default Routes;



