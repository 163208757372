import Layout from "../components/Layout";
import VSideo from "../components/video";
import React from "react";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";
import OwlCarousel from "react-owl-carousel2";
import logoWhite from "../assets/img/logo-white-icon.png";
import juiceMug from "../assets/img/juice-mug.jpg";
import Ronali from "../assets/img/Ronali.jpg";
import TestSlider from "../components/TestSlider";
import HomeMobileSlider from "../components/HomeMobileSlider";
import HomeDesktopContent from "../components/HomeDesktopContent";
import HomeFooterContent from "../components/HomeFooterContent";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactGA from 'react-ga';

import $ from "jquery";

import { Helmet } from "react-helmet";

class Index extends React.Component {
  componentDidMount() {
 //   ReactGA.initialize('UA-211459824-2')

    
  }
  render() {
    return (
      <Layout>
        <Helmet>
          {" "}
          <title>About Us | Refreshing Ayurwedic Wellness | RAW</title>
          <meta name="description" content="Making sure that all your daily essential nutrients, minerals, and vitamins are met in one bottle." />
          <link rel="canonical" href="https://rawsrilanka.com/about-raw" />
        </Helmet>
        <div className="banner-area inner aboutRaw">
          <div className="container">
            <div className="">
              <div className="item">
                <div className="row col-lg-11 col-12 mx-auto">
                  <div className="col-lg-6 col-md-6 align-self-center">
                    <div className="banner-inner">
                      <h2 className="text-white">About Raw</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-area pd-top-105 pd-bottom-150">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="thumb">
                  <LazyLoadImage
                    alt=" Introduction to RAW"
                    src={juiceMug} // use normal <img> attributes as props
                  />
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className=" mt-5 mt-lg-0">
                  <h2 className="title col-8 p-0 text-yellow">
                    Introduction to RAW
                  </h2>
                  <p className="pb-2">
                    We live in a time with a higher prevalence of
                    non-communicable diseases (NCD’s) than ever before, with 70%
                    of deaths in Sri Lanka and the world been caused by NCD’s. A
                    major contributor to this growing problem is the lack of
                    awareness on good nutrition and making healthy lifestyle
                    choices.
                  </p>

                  <p className="pb-2">
                    {" "}
                    The minimum daily-recommended intake of fruits and vegetable
                    per person is 5 servings (400g). Which should be 3 parts
                    vegetables and leafy greens to 2 parts fruit.
                  </p>

                  <p className="pb-2">
                    Given the busy lifestyle people lead in today’s society,
                    getting the required daily amount of fresh fruits,
                    vegetables and leafy greens proves difficult and is
                    therefore often a low priority.
                  </p>

                  <p className="pb-2"> This is where RAW comes in!</p>

                  <p className="pb-2">
                    {" "}
                    We make sure that you get your daily requirement of natural
                    essential vitamins, minerals and nutrients in one bottle,
                    delivered right to your doorstep!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-area pd-top-105 pd-bottom-150 water-bubble ">
          <div className="container position-relative take-top1">
            <div className="section-title text-center">
              <h2 className="title ">Meet Ronali</h2>
            </div>
            <div className="row">
              <div className="col-lg-7 align-self-start">
                <div className=" mt-5 mt-lg-0">
                  <p className="pb-2 ">
                    The journey of RAW started on the first Sunday of 2016.
                    While in prayer I heard God speak to me and say “do raw
                    juices” “what are raw juices?” I questioned and once again I
                    heard the same statement “do raw juices”.
                  </p>

                  <p className="pb-2 ">
                    {" "}
                    I decided to research the benefits of raw organic juices.
                    What I discovered absolutely blew my mind. I was convinced
                    that juicing raw organic produce was an answer to living a
                    happy and healthy life.
                  </p>

                  <p className="pb-2 ">
                    {" "}
                    This made my decision to jump in with both feet far more
                    solid and in the months that followed I ordered a cold press
                    juicer from the USA, learned about Gerson therapy, searched
                    for local organic suppliers and learned more about food and
                    nutrition.
                  </p>

                  <p className="pb-2">
                    {" "}
                    I then completed a course from Monash University on “Food as
                    Medicine”, undertook an “Ayurveda as a lifestyle” course in
                    Sri Lanka and worked closely with a ‘paramparika vedha
                    mahaththaya and haminey’ (3rd generation ayurveda doctor and
                    a female ayurveda doctor). I have also completed a
                    certification in Nutrition and I am a certified Nutriton
                    Coach.
                  </p>

                  <p className="pb-2 ">
                    {" "}
                    The journey I embarked on in 2016 has taken me down an
                    amazing path, bringing me closer to my mother who schooled
                    me on local natural remedies which were passed down to her
                    from her mother, and my father who taught me everything I
                    know about starting and running a business. The more I
                    learned, the more I was convinced that this was truly
                    beneficial to people and could change people’s lives.
                  </p>

                  <p className="pb-2">
                    <b> Ronali Perera – Founder RAW Organic Juice</b>{" "}
                  </p>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="thumb">
                  <img src={Ronali} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Index;
