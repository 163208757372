import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/style.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals'; 
import ReactGA from 'react-ga';


const injectGA = () => {

  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

 // gtag('config', 'G-78QMTNTNES');
  gtag('config', 'UA-211459824-2');

};

ReactDOM.render(

  <React.StrictMode>
   {/* Global site tag (gtag.js) - Google Analytics */}
   {/* <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-78QMTNTNES"
    /> */}
    {/* <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-211459824-2"
    />*/
    // <script>{injectGA()}</script> 
    }

    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
