import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer";

import Routes from "./Routes";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div className="App">
            <Header />
            <div className="body">
              <Suspense fallback={<div></div>}>
                <Routes />
              </Suspense>
            </div>
            <Footer />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}
export default App;
